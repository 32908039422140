import { Form, Input, Typography } from "antd";
import React from "react";
import { t } from "i18next";
import { useContext } from "react";
import { StoreContext } from "../../../stores";
import { useState } from "react";
import { socialInsuranceItems } from "../../../configs/const";

function SocialInsurance(props) {
    const { form } = props;
    const { initialForm } = useContext(StoreContext);
    const [data, setData] = useState(
        initialForm?.social_insurance
            ? JSON.parse(initialForm?.social_insurance)
            : {
                  si_code: "",
                  si_salary: "",
                  si_rate: "",
              }
    );

    return (
        <div className="child_groups">
            {socialInsuranceItems.map((item) => (
                <div style={{ marginBottom: 12 }} key={item.key}>
                    <Typography.Text>{t(item.label)}</Typography.Text>
                    <Input
                        onChange={(e) => {
                            setData(Object.assign(data, { [item.key]: e.target.value }));
                            form.setFieldsValue({ social_insurance: JSON.stringify(data) });
                        }}
                        value={data[item.key]}
                        size="large"
                    />
                </div>
            ))}
        </div>
    );
}

export default SocialInsurance;
