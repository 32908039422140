export const routeByRoles = {
    PARENT: ["dashboard"],
    STUDENT: ["dashboard", "educations", "reports", "profile"],
    TEACHER: ["dashboard", "educations", "reports", "profile"],
    MANAGE: ["ALL"],
    ADMIN: ["ALL"],
    SALER: ["dashboard", "teachers", "customers", "students", "parents", "centers", "courses", "profile"],
    ACCOUNTANT: ["dashboard", "staffs", "students", "parents", "centers", "courses", "profile"],
    SUPER_ACCOUNTANT: ["dashboard", "staffs", "students", "parents", "centers", "courses", "profile"],
    FOREIGN_ELEMENTS: ["dashboard", "staffs", "teachers", "centers", "courses", "customers", "profile"],
    HR: ["staffs", "profile"],
};

export const rolesGetStaffProfile = ['ADMIN', 'HR']
