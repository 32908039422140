import { Layout, Select } from "antd";
import React from "react";
import MenuByRoles from "../MenuByRoles";
import LOGO from "../../assets/images/LOGO.png";
import { useTranslation } from "react-i18next";
const { Sider } = Layout;

function CommonSider(props) {
    const {currentUser} = props
    const { i18n } = useTranslation(["auth", "common"]);
    // const { user: currentUser } = useContext(StoreContext);
    return (
        <Sider collapsed={false} width={currentUser !== "UNAUTHEN" ? 250 : 0} className="sider flex__center__center">
            <div className="sider__logo flex__center__center">
                <img src={LOGO} alt="logo" />
            </div>
            <MenuByRoles role={"STUDENT"} />
            <Select className="menu-item-select-language" size="large" defaultValue="vn" onChange={(value) => i18n.changeLanguage(value)}>
                <Select.Option key="vn" value="vn">
                    Tiếng Việt
                </Select.Option>
                <Select.Option key="en" value="en">
                    English
                </Select.Option>
            </Select>
        </Sider>
    );
}

export default CommonSider;
