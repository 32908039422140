import { Form, Input, Typography } from "antd";
import React from "react";
import { t } from "i18next";
import { useContext } from "react";
import { StoreContext } from "../../../stores";
import { useState } from "react";
import { probationaryContractsItems } from "../../../configs/const";

function ProbationaryContracts(props) {
    const { form } = props;
    const { initialForm } = useContext(StoreContext);
    const [data, setData] = useState(
        initialForm?.probationary_contracts
            ? JSON.parse(initialForm?.probationary_contracts)
            : {
                  no: "",
                  time: "",
              }
    );

    return (
        <div className="child_groups">
            {probationaryContractsItems.map((item) => (
                <div style={{ marginBottom: 12 }} key={item.key}>
                    <Typography.Text>{t(item.label)}</Typography.Text>
                    <Input
                        onChange={(e) => {
                            setData(Object.assign(data, { [item.key]: e.target.value }));
                            form.setFieldsValue({ probationary_contracts: JSON.stringify(data) });
                        }}
                        value={data[item.key]}
                        size="large"
                    />
                </div>
            ))}
        </div>
    );
}

export default ProbationaryContracts;
