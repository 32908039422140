import retryHandleApis from "../configs/handleApis";

export const getCenters = (id) => {
  return retryHandleApis(`/center/?pageSize=100&id=${id ? id : ''}`, "GET");
};

export const getCenterById = (id) => {
  return retryHandleApis(`/center/${id}`, "GET");
};

export const filterCenter = (options) => {
  return retryHandleApis(
    `/center?name=${options.name || ""}&hotline=${options.hotline || ""}&address=${options.address || ""}&type=${options.type || ""}`,
    "GET"
  );
};

export const createCenter = (data) => {
  return retryHandleApis("/center", "POST", data);
};
export const updateCenter = (data) => {
  return retryHandleApis(`/center/${data.id}`, "PUT", data);
};

export const getCentersByTeacherId = (id) => {
    return retryHandleApis(`/center/teacher/${id}`, "GET");
};