import React, { useContext, useEffect, useState } from "react";
import { Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { getStaffs, getTeachers } from "../../apis/teachers";
import { getClasses, getClassesByTeacherId, getCourses } from "../../apis/educations";
import { getParents } from "../../apis/parents";
import { getCenters } from "../../apis/centers";
import Cookies from "js-cookie";
import { AuthContext } from "../../stores/auth";
import { StoreContext } from "../../stores";

function SelectOptions(props) {
    const { me } = useContext(StoreContext);

    let ROLE = me?.role;
    const { t } = useTranslation(["common"]);
    const { propsKey, form, initOptions } = props;
    const [options, setOptions] = useState(initOptions);

    const fetchTeachers = async () => {
        const res = await getTeachers();
        setOptions(res.data || []);
    };
    const fetchCourses = async () => {
        const res = await getCourses();
        setOptions(res.data || []);
    };
    const fetchCenters = async () => {
        const res = await getCenters();
        setOptions(res.data || []);
    };
    const fetchClasses = async () => {
        let res;
        switch (ROLE) {
            case "ADMIN":
                res = await getClasses();
                break;

            case "TEACHER":
                res = await getClassesByTeacherId(me?.owner_id);
                break;
            default:
                break;
        }
        setOptions(res?.data || [{ key: 0, label: "Không có lớp nào để hiển thị" }]);
    };
    const fetchParents = async () => {
        const res = await getParents();
        setOptions(res.data || []);
    };

    const fetchStaffs = async () => {
        const res = await getStaffs();
        setOptions(res.data || []);
    };

    const handleForcusSelect = async (key) => {
        switch (key) {
            case "center_id":
                fetchCenters();
                break;
            case "teacherId":
                fetchTeachers();
                break;
            case "courseId":
                fetchCourses();
                break;
            case "classId":
                fetchClasses();
                break;
            case "parentId":
                fetchParents();
                break;
            // case "accounts":
            //     fetchTeachers();
            //     break;
            case "take_care_by":
                fetchStaffs();
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        !options && handleForcusSelect(propsKey);
    }, [options]);

    return (
        <Select
            onChange={(value, record) => {
                form.setFieldsValue({ [propsKey]: value, [`${propsKey}_name`]: record.children });
                form.setFieldsValue({ take_care_name: record.children });
                propsKey === "classId" && ROLE === "TEACHER" && form.setFieldsValue({ room: options?.filter((option) => option.id === value)[0]?.room });
            }}
            size="large"
        >
            {options && options.length ? (
                options.map((option, index) => (
                    <Select.Option value={option.id} key={option.key || index}>
                        {t(option.label) || t(option.name) || t(option.code)}
                    </Select.Option>
                ))
            ) : (
                <Select.Option value={0} key={0}>
                    No Data
                </Select.Option>
            )}
        </Select>
    );
}

export default SelectOptions;
