import React, { useContext, useEffect, useState } from "react";
import { Layout, Space, Avatar, Popover, Badge, Dropdown, Menu, message, Typography, Breadcrumb } from "antd";
import { AuthContext } from "../../stores/auth";
import { NotificationOutlined, UserOutlined, LogoutOutlined, CalendarOutlined, FileTextOutlined } from "@ant-design/icons";
import { createNotify, getNotify } from "../../apis/notifications";
import { Link } from "react-router-dom";
import { StoreContext } from "../../stores";
import { t } from "i18next";
import CreateForm from "../CreateForm";
import { breakFormField } from "../../configs/options";
import Notification from "../Notification";

const { Header } = Layout;

function HeaderComponent(props) {
    const { currentUser } = props;
    const { logout } = useContext(AuthContext);
    const { setVisibleModal, setUpdateNotify, updateNotify, setLoading, setDrawerContent, setVisibleDrawer, setInitialForm } = useContext(StoreContext);
    const [notifies, setNotifies] = useState();
    const [unread, setUnread] = useState(0);
    const fetchNotifies = async () => {
        setUnread(0);
        let response;
        response = await getNotify();
        if (response.status === 200) {
            let count = 0;
            setNotifies(response.data || []);
            response.data.forEach((notify) => {
                !notify.is_read && setUnread(++count);
            });
        }
        setUpdateNotify(false);
    };

    useEffect(() => {
        !notifies && fetchNotifies();
    }, [notifies]);

    useEffect(() => {
        updateNotify && fetchNotifies();
    }, [updateNotify]);

    const onCreateRequest = async (values) => {
        setLoading(true);
        const res = await createNotify(Object.assign(values, { accounts: [1] }));
        if (res.status === 200) {
            message.success("Gửi yêu cầu thành công!");
            setVisibleDrawer(false);
        } else {
            message.error("Gửi yêu cầu thất bại !");
        }
        setLoading(false);
    };

    const menu = (
        <Menu>
            <Menu.Item key="info_user">
                <Link to="/me"><UserOutlined /> Thông tin cá nhân</Link>
            </Menu.Item>
            <Menu.Item
                onClick={() => {
                    setLoading(true);
                    setVisibleModal(false);
                    setInitialForm({
                        type: 2,
                    });
                    setDrawerContent({
                        title: t("create_student"),
                        content: <CreateForm form_name="create_student" onFinish={onCreateRequest} form_items={breakFormField} />,
                    });
                    setVisibleDrawer(true);
                    setLoading(false);
                }}
                key="take_a_break_day"
            >
                <CalendarOutlined /> Xin nghỉ ngày
            </Menu.Item>
            <Menu.Item key="take_a_break_slot">
                <FileTextOutlined /> Xin nghỉ tiết
            </Menu.Item>
            <Menu.Item
                key="logout"
                onClick={() => {
                    logout();
                }}
            >
                <LogoutOutlined /> Đăng xuất
            </Menu.Item>
        </Menu>
    );

    const renderListNotifies = () => {
        return notifies && notifies.map((noti) => <Notification key={noti.id} user_id={currentUser && currentUser.id} notify={noti} />);
    };

    return (
        currentUser && (
            <Header className="flex__between__center">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>page</Breadcrumb.Item>
                </Breadcrumb>
                <Space size="large">
                    <Popover trigger="click" placement="bottomLeft" overlayClassName="popover_noti" content={renderListNotifies()}>
                        <Badge count={unread}>
                            <Avatar size="large" style={{ color: "#ffffff", backgroundColor: "#ffbb00" }}>
                                <NotificationOutlined />
                            </Avatar>
                        </Badge>
                    </Popover>

                    <Typography.Text>{currentUser?.name}</Typography.Text>
                    <Dropdown overlay={menu}>
                        <Avatar size="large" style={{ color: "#f0f0f0", backgroundColor: "#3a4559" }}>
                            M
                        </Avatar>
                    </Dropdown>
                </Space>
            </Header>
        )
    );
}

export default HeaderComponent;
