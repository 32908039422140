// define
const national_options = [
    {
        key: "english",
        label: "english",
    },
    {
        key: "vietnamese",
        label: "vietnamese",
    },
    {
        key: "american",
        label: "american",
    },
    {
        key: "australia",
        label: "australia",
    },
    {
        key: "canada",
        label: "canada",
    },
    {
        key: "new_zealand",
        label: "new_zealand",
    },
    {
        key: "ireland",
        label: "ireland",
    },
    {
        key: "south_africa",
        label: "south_africa",
    },
];

const subjects_options = [
    {
        id: 4,
        created_at: "2021-12-01T15:16:33.289Z",
        updated_at: "2021-12-01T15:16:33.289Z",
        code: "AdENG",
    },
    {
        id: 5,
        created_at: "2021-12-01T15:17:06.068Z",
        updated_at: "2021-12-01T15:17:06.068Z",
        code: "StdENG",
    },
    {
        id: 6,
        created_at: "2021-12-01T15:17:24.139Z",
        updated_at: "2021-12-01T15:17:24.140Z",
        code: "ESL",
    },
    {
        id: 2,
        created_at: "2021-11-30T15:14:53.232Z",
        updated_at: "2021-11-30T15:14:53.232Z",
        code: "TNF",
    },
    {
        id: 1,
        created_at: "2021-11-30T15:13:13.150Z",
        updated_at: "2021-11-30T15:30:54.699Z",
        code: "OJN",
    },
    {
        id: 9,
        created_at: "2021-12-01T16:06:16.602Z",
        updated_at: "2021-12-01T16:06:16.603Z",
        code: "IE",
    },
    {
        id: 13,
        created_at: "2022-05-24T10:28:27.314Z",
        updated_at: "2022-05-24T10:28:27.314Z",
        code: "XIT11",
    },
    {
        id: 21,
        created_at: "2022-09-12T09:49:00.813Z",
        updated_at: "2022-09-12T09:49:00.813Z",
        code: "MATH",
    },
    {
        id: 23,
        created_at: "2022-09-12T09:57:38.036Z",
        updated_at: "2022-09-12T09:57:38.036Z",
        code: "SCI",
    },
    {
        id: 24,
        created_at: "2022-09-12T09:57:50.903Z",
        updated_at: "2022-09-12T09:57:50.903Z",
        code: "SCIE",
    },
    {
        id: 25,
        created_at: "2022-09-12T09:57:58.418Z",
        updated_at: "2022-09-12T09:57:58.418Z",
        code: "ICT",
    },
    {
        id: 26,
        created_at: "2022-09-12T09:58:04.506Z",
        updated_at: "2022-09-12T09:58:04.506Z",
        code: "DRM",
    },
    {
        id: 27,
        created_at: "2022-09-12T09:58:09.897Z",
        updated_at: "2022-09-12T09:58:09.897Z",
        code: "GP",
    },
    {
        id: 28,
        created_at: "2022-09-12T09:58:15.502Z",
        updated_at: "2022-09-12T09:58:15.502Z",
        code: "CS21",
    },
    {
        id: 29,
        created_at: "2022-09-12T09:58:23.814Z",
        updated_at: "2022-09-12T09:58:23.814Z",
        code: "XPM01",
    },
    {
        id: 30,
        created_at: "2022-09-12T09:58:29.341Z",
        updated_at: "2022-09-12T09:58:29.341Z",
        code: "XFM01",
    },
    {
        id: 31,
        created_at: "2022-09-12T09:58:35.094Z",
        updated_at: "2022-09-12T09:58:35.094Z",
        code: "XPH11",
    },
    {
        id: 32,
        created_at: "2022-09-12T09:58:41.050Z",
        updated_at: "2022-09-12T09:58:41.050Z",
        code: "XCH11",
    },
    {
        id: 33,
        created_at: "2022-09-12T09:58:47.038Z",
        updated_at: "2022-09-12T09:58:47.038Z",
        code: "XBI11",
    },
    {
        id: 34,
        created_at: "2022-09-12T09:58:52.251Z",
        updated_at: "2022-09-12T09:58:52.251Z",
        code: "XBS11",
    },
    {
        id: 35,
        created_at: "2022-09-12T09:58:57.464Z",
        updated_at: "2022-09-12T09:58:57.464Z",
        code: "XEC11",
    },
    {
        id: 36,
        created_at: "2022-09-12T09:59:27.366Z",
        updated_at: "2022-09-12T09:59:27.366Z",
        code: "YPM01",
    },
    {
        id: 37,
        created_at: "2022-09-12T09:59:35.172Z",
        updated_at: "2022-09-12T09:59:35.172Z",
        code: "YPH11",
    },
    {
        id: 38,
        created_at: "2022-09-12T09:59:39.485Z",
        updated_at: "2022-09-12T09:59:39.485Z",
        code: "YCH11",
    },
    {
        id: 39,
        created_at: "2022-09-12T09:59:44.964Z",
        updated_at: "2022-09-12T09:59:44.964Z",
        code: "YBI11",
    },
    {
        id: 40,
        created_at: "2022-09-12T09:59:49.801Z",
        updated_at: "2022-09-12T09:59:49.801Z",
        code: "YBS11",
    },
    {
        id: 41,
        created_at: "2022-09-12T09:59:55.716Z",
        updated_at: "2022-09-12T09:59:55.716Z",
        code: "YEC11",
    },
    {
        id: 42,
        created_at: "2022-09-12T10:00:01.372Z",
        updated_at: "2022-09-12T10:00:01.372Z",
        code: "YIT11",
    },
];

const position_options = [
    {
        key: "academic_director",
        label: "academic_director",
    },
    {
        key: "foreign_teacher",
        label: "foreign_teacher",
    },
    {
        key: "native_teacher",
        label: "native_teacher",
    },
    {
        key: "assistant_teacher",
        label: "assistant_teacher",
    },
];

const contract_options = [
    {
        key: "partime",
        label: "partime",
    },
    {
        key: "fulltime",
        label: "fulltime",
    },
];


const field = (key, type, required, init) => ({
    key: key,
    label: key,
    type: type,
    required: required === false ? false : true,
    options: init,
    checkbox: init,
});

const name = field("name", "string");

const code = field("code", "string");

const email = field("email", "string");

const content = field("content", "string", false);

const password = field("password", "password");

const phone = field("phone", "number", false);

const identity = field("identity", "number");

const address = field("address", "string");

const photo = field("photo", "file", false);

const source = field("source", "string", false);

const take_care_by = field("take_care_by", "select");

const take_care_name = field("take_care_name", "hidden");

const dob = field("dob", "datetime", false);

const datepicker = field("dob", "datepicker", false);

const from = field("from", "hidden");

const to = field("to", "hidden");

const price = field("price", "string", false);

const class_name = field("class_name", "string", false);

const school = field("school", "string", false);

const parent_name = field("parent_name", "string", false);

const weight = field("weight", "string", false);

const height = field("height", "string", false);

const diseases = field("diseases", "string", false);

const care_about = field("care_about", "string", false);

const current_pay = field("current_pay", "string", false);

const total_pay = field("total_pay", "string", false);

const use_bus = field("use_bus", "checkbox", false);

const special_note = field("special_note", "string", false);

const sales_process = field("sales_process", "sale_process", false);

const result = field("result", "string", false);

const contractType = field("contractType", "select", true, contract_options);

const nationality = field("nationality", "select", true, national_options);

const subjects = field("subjects", "checkbox_groups", true, subjects_options);

const subject_config_id = field("subject_config_id", "select", true, subjects_options);

const position = field("position", "select", true, position_options);

const parent_id = field("parent_id", "select", true);

const center_id = field("center_id", "select", true);

const center_type = field("center_type", "select", true, [
    {
        key: "center",
        label: "center",
    },
    {
        key: "school",
        label: "school",
    },
]);

const daterange = field("daterange", "daterange", false);

const room = field("room", "string", true);

const datetime = field("datetime", "datetime", false);

const classId = field("classId", "select", true);

const slotId = field("slotId", "hidden", false);

const timerange = field("timerange", "timerange", false);

const proportion = field("proportion", "number", true);

const sex = field("sex", "string", false);

const payment = field("payment", "payment", false);

const probationary_contracts = field("probationary_contracts", "probationary_contracts", false);

const education = field("education", "education", false);

const social_insurance = field("social_insurance", "social_insurance", false);

const taxpayers = field("taxpayers", "string", false);

const tax_code = field("tax_code", "string", false);

const identification_info = field("identification_info", "identification_info", false);

const permanent_address = field("permanent_address", "string", false);

const current_address = field("current_address", "string", false);

const work_process = field("work_process", "work_process", false);

const start_at = field("start_at", "datepicker", false);

const job_title = field("job_title", "string", false);

export {
    job_title,
    permanent_address,
    current_address,
    work_process,
    start_at,
    identification_info,
    tax_code,
    taxpayers,
    social_insurance,
    education,
    probationary_contracts,
    payment,
    sex,
    proportion,
    timerange,
    slotId,
    classId,
    datetime,
    room,
    name,
    code,
    email,
    password,
    phone,
    identity,
    address,
    photo,
    datepicker,
    national_options,
    subjects_options,
    position_options,
    contract_options,
    class_name,
    school,
    parent_name,
    weight,
    height,
    diseases,
    current_pay,
    total_pay,
    use_bus,
    special_note,
    care_about,
    sales_process,
    result,
    source,
    take_care_by,
    take_care_name,
    dob,
    contractType,
    nationality,
    subjects,
    position,
    parent_id,
    center_type,
    center_id,
    subject_config_id,
    daterange,
    content,
    price,
    from,
    to,
};

// teacher options configs
// export const teacherFilterOptions = [
//     {
//         key: "code",
//         label: "code",
//     },
//     {
//         key: "name",
//         label: "name_label",
//     },
//     {
//         key: "email",
//         label: "email_label",
//     },
//     {
//         key: "nationality",
//         label: "nationality_label",
//         options: national_options,
//     },
//     {
//         key: "subjects",
//         label: "subject_label",
//         options: subjects_options,
//     },
//     {
//         key: "position",
//         label: "position_label",
//         options: position_options,
//     },
// ];

export const teacherFormFields = [
    {
        key: "code",
        label: "code",
        required: true,
    },
    {
        key: "email",
        label: "email_label",
        required: true,
    },
    {
        key: "password",
        label: "password_label",
        required: false,
    },
    {
        key: "name",
        label: "name_label",
        required: true,
    },
    {
        key: "phone",
        label: "phone_number_label",
        required: true,
    },
    {
        key: "identity",
        label: "identity_number_label",
        required: true,
    },
    {
        key: "address",
        label: "address_label",
        required: false,
    },
    {
        key: "contractType",
        label: "type_of_contract_label",
        options: contract_options,
        required: true,
    },
    {
        key: "nationality",
        label: "nationality_label",
        options: national_options,
        required: true,
    },
    {
        key: "subjects",
        label: "subject_label",
        checkbox: subjects_options,
        required: true,
    },
    {
        key: "position",
        label: "position_label",
        options: position_options,
        required: true,
    },
    {
        key: "photo",
        label: "photo",
        required: false,
    },
];

export const teacherContractFormFields = [
    {
        key: "daterange",
        label: "daterange",
        required: false,
    },
    {
        key: "contract_file",
        label: "contract_file",
        required: false,
    },
];
export const teacherVisaFormFields = [
    {
        key: "visa_expiration_date",
        label: "daterange",
        required: false,
    },
    {
        key: "visa_type",
        label: "visa_type",
        required: false,
    },
];

// parents options configs
export const parentFilterOptions = [
    {
        key: "name",
        label: "name_label",
    },
    {
        key: "phone",
        label: "phone_number_label",
    },
    {
        key: "email",
        label: "email_label",
    },
];

export const parentFormFields = [
    {
        key: "email",
        label: "email_label",
        required: true,
    },
    {
        key: "password",
        label: "password_label",
        required: false,
    },
    {
        key: "name",
        label: "name_label",
        required: true,
    },
    {
        key: "phone",
        label: "phone_number_label",
        required: true,
    },
    {
        key: "dob",
        label: "birthdate_label",
        required: false,
    },
    {
        key: "address",
        label: "address_label",
        required: false,
    },
    {
        key: "identity",
        label: "identity_number_label",
        required: true,
    },
];
// students options configs
export const studentFilterOptions = [
    {
        key: "code",
        label: "CODE",
    },
    {
        key: "name",
        label: "name_label",
    },
    {
        key: "classId",
        label: "class_label",
        options: subjects_options,
    },
];

export const studentHasParentFormFields = [
    {
        key: "parentId",
        label: "parents",
        required: true,
        options: null,
    },
    {
        key: "name",
        label: "name_label",
        required: true,
    },
    {
        key: "dob",
        label: "birthdate_label",
        required: false,
    },
    {
        key: "photo",
        label: "photo",
        required: false,
    },
];

export const studentFormFields = [
    {
        key: "email",
        label: "email",
        required: false,
    },
    {
        key: "password",
        label: "password_label",
        required: false,
    },
    {
        key: "name",
        label: "name_label",
        required: true,
    },
    {
        key: "phone",
        label: "phone_number_label",
        required: true,
    },
    {
        key: "dob",
        label: "birthdate_label",
        required: false,
    },
    {
        key: "identity",
        label: "identity_number_label",
        required: true,
    },
    {
        key: "address",
        label: "address_label",
        required: false,
    },
    {
        key: "photo",
        label: "photo",
        required: false,
    },
];

// centers
export const centerFilterOptions = [
    {
        key: "group",
        label: "group",
    },
    {
        key: "name",
        label: "center_name_label",
    },
    {
        key: "address",
        label: "address_label",
    },
    {
        key: "type",
        label: "type_label",
        required: true,
        options: [
            {
                key: "center",
                label: "center",
            },
            {
                key: "school",
                label: "school",
            },
        ],
    },
];

export const centerFormFields = [
    {
        key: "name",
        label: "center_name_label",
        required: true,
    },
    {
        key: "address",
        label: "address_label",
        required: true,
    },
    // {
    //     key: "hotline",
    //     label: "hotline",
    //     required: false,
    // },
    {
        key: "type",
        label: "type",
        required: true,
        options: [
            {
                key: "center",
                label: "center",
            },
            {
                key: "school",
                label: "school",
            },
        ],
    },
    {
        key: "photo",
        label: "background",
        required: false,
    },
];

// courses
export const coursesFilterOptions = [
    {
        key: "name",
        label: "course_name_label",
    },
    {
        key: "code",
        label: "course_code",
    },
    {
        key: "subjects",
        label: "subject_label",
        options: subjects_options,
    },
];

export const courseFormFields = [
    {
        key: "center_id",
        label: "center_label",
        required: true,
        options: null,
    },
    {
        key: "name",
        label: "course_name_label",
        required: true,
    },
    {
        key: "subject_config_id",
        label: "subject_label",
        options: subjects_options,
    },
    {
        key: "code",
        label: "course_code",
        required: true,
    },
    // {
    //     key: "from",
    //     label: "from",
    //     required: true,
    // },
    // {
    //     key: "to",
    //     label: "to",
    //     required: true,
    // },
    {
        key: "daterange",
        label: "daterange",
        required: false,
    },
    {
        key: "content",
        label: "content",
        required: false,
    },
    {
        key: "cost_default",
        label: "Price",
        required: false,
        type: "number",
    },
    {
        key: "discount",
        label: "discount",
        required: false,
    },
    {
        key: "photo",
        label: "background",
        required: false,
    },
    {
        key: "bookId",
        label: "book_id_label",
        required: false,
    },
];

export const classFormField = [
    {
        key: "courseId",
        label: "course_label",
        required: true,
        options: null,
    },
    {
        key: "name",
        label: "name_label",
        required: true,
    },
    {
        key: "code",
        label: "code",
        required: true,
    },
    {
        key: "teacherId",
        label: "teacher",
        required: true,
        options: null,
    },
    {
        key: "teacherId_name",
        label: "teacher_name",
        required: false,
        hidden: true,
    },
    {
        key: "room",
        label: "class_room",
        required: true,
    },
];

// exams
export const examsFilterOptions = [
    {
        key: "name",
        label: "exam_label",
    },
    {
        key: "from",
        label: "from",
    },
    {
        key: "to",
        label: "to",
    },
];

export const examFormField = [
    {
        key: "name",
        label: "exam_label",
        required: true,
    },
    {
        key: "from",
        label: "from",
        required: true,
    },
    {
        key: "to",
        label: "to",
        required: true,
    },
    {
        key: "course",
        label: "course",
    },
    {
        key: "class",
        label: "class",
    },
    {
        key: "slotId",
        label: "slot",
        required: true,
    },
];

// class
export const classesFilterOptions = [
    {
        key: "name",
        label: "class_label",
    },
    {
        key: "courseId",
        label: "course_label",
    },
];

export const slotFormField = [
    {
        key: "name",
        label: "slot_label",
        required: true,
    },
    {
        key: "content",
        label: "content",
        required: false,
    },
    {
        key: "classId",
        label: "class",
        required: true,
        options: null,
    },
    {
        key: "teacherId",
        label: "teacher",
        required: true,
        options: null,
    },
    {
        key: "room",
        label: "class_room",
        required: true,
    },
    {
        key: "datetime",
        label: "Datetime",
        required: false,
    },
    // {
    //     key: "from",
    //     label: "from",
    //     required: true,
    // },
    // {
    //     key: "to",
    //     label: "to",
    //     required: true,
    // },
];

export const subjectFormField = [
    {
        key: "code",
        label: "code",
        required: true,
    },
    {
        key: "name",
        label: "name",
        required: true,
    },
];

export const breakFormField = [
    {
        key: "title",
        label: "title",
        required: true,
    },
    {
        key: "body",
        label: "body",
        required: true,
    },
    {
        key: "date_range",
        label: "day",
        required: true,
    },
    {
        key: "accounts",
        label: "Select manager",
        required: true,
        options: null,
    },
];

export const fileFormField = [
    {
        key: "photo",
        label: "file",
        required: false,
    },
    {
        key: "name",
        label: "file_name",
        required: false,
    },
    {
        key: "tag",
        label: "tag",
        required: false,
    },
];
