/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";

export const StoreContext = React.createContext(null);

export default ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [drawerContent, setDrawerContent] = useState({});
    const [visibleModal, setVisibleModal] = useState(false);
    const [modalContent, setModalContent] = useState();
    const [password, setPassword] = useState();
    const [imageUpload, setImageUpload] = useState();
    const [initialForm, setInitialForm] = useState();
    const [updateNotify, setUpdateNotify] = useState(false);
    const [me, setMe] = useState();
    const [breadcrumb, setBreadcrumb] = useState({
        key: "dashboard",
        link: "/",
    });

    const stores = {
        loading: loading,
        setLoading: setLoading,
        visibleDrawer: visibleDrawer,
        setVisibleDrawer: setVisibleDrawer,
        drawerContent: drawerContent,
        setDrawerContent: setDrawerContent,
        visibleModal: visibleModal,
        setVisibleModal: setVisibleModal,
        modalContent: modalContent,
        setModalContent: setModalContent,
        password: password,
        setPassword: setPassword,
        imageUpload: imageUpload,
        setImageUpload: setImageUpload,
        initialForm: initialForm,
        setInitialForm: setInitialForm,
        updateNotify: updateNotify,
        setUpdateNotify: setUpdateNotify,
        me: me,
        setMe: setMe,
        breadcrumb: breadcrumb,
        setBreadcrumb: setBreadcrumb
    };

    return <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>;
};
