import React from "react";
import ReactDOM from "react-dom";
import "./index.less";
import App from "./pages";
// import reportWebVitals from "./reportWebVitals";
import StoreProvider from "./stores";
import AuthProvider from "./stores/auth";
import "./configs/language";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryClient = new QueryClient();

ReactDOM.render(
    // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StoreProvider>
        <AuthProvider>
            <App />
        </AuthProvider>
    </StoreProvider>
    </QueryClientProvider>
    ,
    // </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
