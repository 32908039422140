import { Checkbox, Col, Row } from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { getSubjects } from "../../apis/educations";

function CheckboxGroup(props) {
  const { propsKey, form } = props;
  const [checkboxList, setCheckboxList] = useState();

  const fetchSubjects = async () => {
    const response = await getSubjects();
    response && setCheckboxList(response.data || []);
  };

  useEffect(() => {
    !checkboxList && fetchSubjects();
  }, [checkboxList]);

  return (
    <Checkbox.Group
      onChange={(checked) => {
        form.setFieldsValue({ subjects: checked });
      }}
      style={{ width: "100%" }}
    >
      <Row>
        {checkboxList &&
          checkboxList.map((i, index) => (
            <Col key={index} span={12}>
              <Checkbox value={i.code}>{t(i.code)}</Checkbox>
            </Col>
          ))}
      </Row>
    </Checkbox.Group>
  );
}

export default CheckboxGroup;
