import retryHandleApis from "../configs/handleApis";

export const getTeachers = () => {
  return retryHandleApis("/teacher?page=1&pageSize=100", "GET");
};
export const getTeacherById = (id) => {
  return retryHandleApis(`/teacher/${id}`, "GET");
};

export const filterTeachers = (options) => {
  return retryHandleApis(
    `/teacher?name=${options.name || ""}&code=${options.code || ""}&email=${options.email || ""}&phone=${options.phone || ""}&position=${
      options.position || ""
    }&nationality=${options.nationality || ""}&center_id=${options.center_id || ""}&course_id=${options.course_id || ""}`,
    "GET"
  );
};

export const createTeacher = (data) => {
  return retryHandleApis("/teacher", "POST", data);
};
export const getTeacherByCourse = (id) => {
  return retryHandleApis(`/teacher/course/${id}?page=1&pageSize=100`, "GET");
};

export const getStaffs = () => {
  return retryHandleApis("/staffs", "GET");
};
