import Cookies from "js-cookie";
import { lazy } from "react";
import { routeByRoles } from "./roles";

const lazyLoad = (url) => {
    return lazy(() => import("../pages/" + url));
};

// check user have access denied to page
const checkAccessDenied = (route) => {
    const ROLE = Cookies.get("role")
    if(ROLE && routeByRoles[ROLE][0] === 'ALL') return true
    return routeByRoles[ROLE]?.includes(route);
}

export const routes = [
    {
        path: "/",
        exact: true,
        component: checkAccessDenied("dashboard") ? lazyLoad("Dashboard") : lazyLoad("AccessDenied"),
    },
    {
        path: "/dashboard",
        exact: true,
        component: checkAccessDenied("dashboard") ? lazyLoad("Dashboard") : lazyLoad("AccessDenied"),
    },
    {
        path: "/staffs",
        exact: true,
        component: checkAccessDenied("staffs") ? lazyLoad("Staffs") : lazyLoad("AccessDenied"),
    },
    {
        path: "/me",
        exact: true,
        component: lazyLoad("Me"),
    },
    {
        path: "/employee/:staff_type",
        exact: true,
        component: checkAccessDenied("employees") ? lazyLoad("Employee") : lazyLoad("AccessDenied"),
    },
    {
        path: "/customers",
        exact: true,
        component: checkAccessDenied("customers") ? lazyLoad("Customers") : lazyLoad("AccessDenied"),
    },
    {
        path: "/teachers",
        exact: true,
        component: checkAccessDenied("teachers") ? lazyLoad("Teachers") : lazyLoad("AccessDenied"),
    },
    // {
    //     path: "/profile/teacher/:profile_id",
    //     exact: true,
    //     component: checkAccessDenied("profile") ? lazyLoad("ProfileUser") : lazyLoad("AccessDenied"),
    // },
    // {
    //     path: "/profile/student/:profile_id",
    //     exact: true,
    //     component: checkAccessDenied("profile") ? lazyLoad("Profile") : lazyLoad("AccessDenied"),
    // },
    // {
    //     path: "/profile/parent/:profile_id",
    //     exact: true,
    //     component: checkAccessDenied("profile") ? lazyLoad("Profile") : lazyLoad("AccessDenied"),
    // },
    // {
    //     path: "/profile/center/:profile_id",
    //     exact: true,
    //     component: checkAccessDenied("profile") ? lazyLoad("Profile") : lazyLoad("AccessDenied"),
    // },
    // {
    //     path: "/profile/course/:profile_id",
    //     exact: true,
    //     component: checkAccessDenied("profile") ? lazyLoad("Profile") : lazyLoad("AccessDenied"),
    // },
    {
        path: "/students",
        exact: true,
        component: checkAccessDenied("students") ? lazyLoad("Students") : lazyLoad("AccessDenied"),
    },
    {
        path: "/parents",
        exact: true,
        component: checkAccessDenied("parents") ? lazyLoad("Parents") : lazyLoad("AccessDenied"),
    },
    {
        path: "/centers",
        exact: true,
        component: checkAccessDenied("centers") ? lazyLoad("Centers") : lazyLoad("AccessDenied"),
    },
    {
        path: "/center/:center_id",
        exact: true,
        component: checkAccessDenied("centers") ? lazyLoad("ProfileCenter") : lazyLoad("AccessDenied"),
    },
    {
        path: "/courses",
        exact: true,
        component: checkAccessDenied("courses") ? lazyLoad("Courses") : lazyLoad("AccessDenied"),
    },
    {
        path: "/classes",
        exact: true,
        component: checkAccessDenied("classes") ? lazyLoad("Classes") : lazyLoad("AccessDenied"),
    },
    {
        path: "/profile/:profile_type/:profile_id",
        exact: true,
        component: checkAccessDenied("profile") ? lazyLoad("Profile") : lazyLoad("AccessDenied"),
    },
    {
        path: "/exams",
        exact: true,
        component: checkAccessDenied("exams") ? lazyLoad("Exams") : lazyLoad("AccessDenied"),
    },
    {
        path: "/educations",
        exact: true,
        component: checkAccessDenied("educations") ? lazyLoad("Slots") : lazyLoad("AccessDenied"),
    },
    {
        path: "/courses/:course_id/classes/:class_id/slots",
        exact: true,
        component: checkAccessDenied("classes") ? lazyLoad("Slots") : lazyLoad("AccessDenied"),
    },
    {
        path: "/courses/:course_id/classes/:class_id/students",
        exact: true,
        component: checkAccessDenied("students") ? lazyLoad("Students") : lazyLoad("AccessDenied"),
    },
    {
        path: "/class/:class_id",
        exact: true,
        component: checkAccessDenied("educations") ? lazyLoad("ManageClassByTeacher") : lazyLoad("AccessDenied"),
    },
    {
        path: "/class/:class_id/slot/:slot_id",
        exact: true,
        component: checkAccessDenied("educations") ? lazyLoad("ManageClassByTeacher") : lazyLoad("AccessDenied"),
    },
    // reports dashboard
    {
        path: "/report",
        exact: true,
        component: checkAccessDenied("educations") ? lazyLoad("Reports") : lazyLoad("AccessDenied"),
    },
    {
        path: "/report/class/:class_id", // report by class
        exact: true,
        component: checkAccessDenied("educations") ? lazyLoad("Reports") : lazyLoad("AccessDenied"),
    },
    {
        path: "/report/class/:class_id/student/:student_id/mark", // report mark by class
        exact: true,
        component: checkAccessDenied("educations") ? lazyLoad("Reports") : lazyLoad("AccessDenied"),
    },
    {
        path: "/report/class/:class_id/student/:student_id/attendence", // report attendence by class
        exact: true,
        component: checkAccessDenied("educations") ? lazyLoad("Reports") : lazyLoad("AccessDenied"),
    },
    {
        path: "/report/student", // report mark off student
        exact: true,
        component: checkAccessDenied("educations") ? lazyLoad("Students") : lazyLoad("AccessDenied"),
    },
    {
        path: "/report/student/:student_id/mark", // report mark off student
        exact: true,
        component: checkAccessDenied("educations") ? lazyLoad("Reports") : lazyLoad("AccessDenied"),
    },
    {
        path: "/report/student/:student_id/attendence", // report attendence of student
        exact: true,
        component: checkAccessDenied("educations") ? lazyLoad("Reports") : lazyLoad("AccessDenied"),
    },
    {
        path: "/report/mark", // report attendence of student
        exact: true,
        component: checkAccessDenied("educations") ? lazyLoad("Reports/ReportMarkByClass") : lazyLoad("AccessDenied"),
    },

    // Authentication Router
    {
        path: "/login",
        exact: true,
        component: lazyLoad("Login"),
    },
    {
        path: "/forgot-password",
        exact: true,
        component: lazyLoad("ForgotPassword"),
    },
    {
        path: "",
        exact: true,
        component: lazyLoad("NotFound"),
    },
];
