import { Button, Input, Typography } from "antd";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { StoreContext } from "../../../stores";
import { t } from "i18next";

// let tempProcess = [
//     "Học bổng? Đầu ra, danh sách trường con có thể học ở Sing và 1 số quốc gia khác. A-Level có phù hợp không. Học AL có thi được Ielts không, các trường đại học ở Sing và Canada con có thể theo học khi kết thúc chương trình học AL",
//     "Con đổi lịch test vào 24/12/21.Thời gian test dự kiến: Test trường:  khoảng 15h30 - 16h45 Test hệ AL: 17h00 GV test hệ: Thầy Jovan",
//     "26/12: mẹ ghi danh trong ngày hội thảo online để được tặng khoá tiền tiểu học. Lịch test dự kiến vẫn vào 9h00 thứ 7 ngày 22/01/2022",
// ];

const removeLastItemArray = (array) => {
    console.log("array::", array);
    array.pop();
    return array.slice();
};

const ProcessInput = (props) => {
    const { process, index, workProcess } = props;
    const [time, setTime] = useState(process?.time);
    const [position, setPosition] = useState(process?.position);
    const [salary, setSalary] = useState(process?.salary);
    return (
        <div className="flex__between__center" style={{ gap: "0.5rem" }}>
            <div>
                <Typography.Text>{t("time")}</Typography.Text>
                <Input
                    style={{ marginBottom: 12 }}
                    size="large"
                    value={time}
                    onChange={(e) => {
                        setTime(e.target.value);
                        let newProcess = workProcess;
                        process.time = e.target.value;
                        newProcess[index - 1] = { ...process };
                        props.actions(newProcess.slice());
                    }}
                />
            </div>
            <div>
                <Typography.Text>Vị trí</Typography.Text>
                <Input
                    style={{ marginBottom: 12 }}
                    size="large"
                    value={position}
                    onChange={(e) => {
                        setPosition(e.target.value);
                        let newProcess = workProcess;
                        process.position = e.target.value;
                        newProcess[index - 1] = { ...process };
                        props.actions(newProcess.slice());
                    }}
                />
            </div>
            <div>
                <Typography.Text>Mức lương</Typography.Text>
                <Input
                    style={{ marginBottom: 12 }}
                    size="large"
                    value={salary}
                    onChange={(e) => {
                        setSalary(e.target.value);
                        let newProcess = workProcess;
                        process.salary = e.target.value;
                        newProcess[index - 1] = { ...process };
                        props.actions(newProcess.slice());
                    }}
                />
            </div>
        </div>
    );
};

function WorkProcess(props) {
    const { initialForm } = useContext(StoreContext);
    const [workProcess, setWorkProcess] = useState(
        initialForm?.work_process
            ? JSON.parse(initialForm?.work_process)
            : [
                  {
                      time: "",
                      position: "",
                      salary: "",
                  },
              ]
    );
    const { form } = props;
    const handleAddProcess = () => {
        let newProcess = workProcess;
        newProcess.push({
            time: "",
            position: "",
            salary: "",
        });
        setWorkProcess(newProcess.slice());
    };

    useEffect(() => {
        form.setFieldsValue({ work_process: JSON.stringify(workProcess) });
    }, [workProcess]);
    return (
        <div className="child_groups">
            {workProcess?.length ? (
                workProcess?.map((process, index) => (
                    <ProcessInput workProcess={workProcess} actions={setWorkProcess} key={index} process={process} index={index + 1} />
                ))
            ) : (
                <ProcessInput workProcess={workProcess} actions={setWorkProcess} process={null} index={1} />
            )}
            <Button type="dashed" style={{ width: "100%", marginBottom: 10 }} onClick={handleAddProcess}>
                THÊM
            </Button>
        </div>
    );
}

export default WorkProcess;
