import * as user from "./user";
import { message } from "antd";
import { t } from "i18next";

export const handleUpdateProfileUser = async (props, callback) => {
    const mapMessage = new Map();
    mapMessage.set(200, (response) => {
        message.success(t("success"));
        callback(response);
    });
    mapMessage.set(400, () => {
        message.error(t("error"));
    });
    mapMessage.set(500, () => {
        message.error(t("500 ERROR"));
    });

    const { profile_type, profile_id, data } = props;
    const response = await user.updateProfileUser(profile_type, profile_id, data);
    mapMessage.get(response.status || 500)(response);
};

export const handleUpdateContractUser = async (props, callback) => {
    const mapMessage = new Map();
    mapMessage.set(200, (response) => {
        message.success(t("success"));
        callback(response);
    });
    mapMessage.set(400, () => {
        message.error(t("error"));
    });
    mapMessage.set(500, () => {
        message.error(t("500 ERROR"));
    });

    const { profile_id, data } = props;
    const response = await user.updateStudentContract(profile_id, data);
    mapMessage.get(response.status || 500)(response);
};
