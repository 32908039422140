import retryHandleApis from "../configs/handleApis";

export const getCourses = (id) => {
  return retryHandleApis(`/course/?pageSize=100`, "GET");
};
export const getCourseById = (id) => {
  return retryHandleApis(`/course/${id}`, "GET");
};
export const updateCourse = (data) => {
  return retryHandleApis(`/course/${data.id}`, "PUT", data);
};

export const getCoursesByCenterId = (id) => {
  return retryHandleApis(`/course?page=1&pageSize=100${id ? `&center_id=${id}` : ""}`, "GET");
};

export const getClassesByCourseId = (id) => {
  return retryHandleApis(`/class?page=1&pageSize=100${id ? `&courseId=${id}` : ""}`, "GET");
};

export const filterCourse = (options) => {
  return retryHandleApis(`/course?name=${options.name || ""}&code=${options.code || ""}&subject=${options.subject || ""}`, "GET");
};

export const createCourse = (data) => {
  return retryHandleApis("/course", "POST", data);
};
export const createClass = (data) => {
  return retryHandleApis("/class", "POST", data);
};
export const getClasses = () => {
  return retryHandleApis("/class?page=1&pageSize=100", "GET");
};

export const getClassesFree = () => {
  return retryHandleApis("/class/teacher/empty", "GET");
};

export const getClassesByTeacherId = (id) => {
  return retryHandleApis(`/class/teacher/${id}`, "GET");
};
export const getClassesByStudentId = (id) => {
  return retryHandleApis(`/class/student/${id}?pageSize=100`, "GET");
};
export const updateClass = (id, data) => {
  return retryHandleApis(`/class/${id}`, "PUT", data);
};

export const filterClass = (options) => {
  return retryHandleApis(`/class?page=1&pageSize=100&name=${options.name || ""}&courseId=${options.courseId || ""}`, "GET");
};

export const addStudentToClass = (data) => {
  return retryHandleApis(`/class_student`, "POST", data);
};

// Exams APis
export const getExams = () => {
  return retryHandleApis(`/exam`, "GET");
};

export const createExam = (data) => {
  return retryHandleApis("/exam", "POST", data);
};
export const filterExam = (data) => {
  return retryHandleApis(`/exam?page=1&pageSize=5&from=${data.from || ""}&to=${data.to || ""}`, "GET", data);
};

// Slots APis
export const getSlots = () => {
  return retryHandleApis(`/slot`, "GET");
};
export const createSlot = (data) => {
  return retryHandleApis(`/slot`, "POST", data);
};

export const getSlotsByTeacher = (data) => {
  return retryHandleApis(`/slot?page=1&pageSize=50&from=${data.from || ""}&to=${data.to || ""}`, "GET");
};

export const filterSlots = (data) => {
  return retryHandleApis(
    `/slot?page=1&pageSize=100&from=${data.from || ""}&to=${data.to || ""}&${data.profile_type || ""}=${data.teacher_id || ""}&${`classId=${
      data.class_id || ""
    }`}`,
    "GET",
    data
  );
};

export const getUserActivities = (data) => {
  return retryHandleApis(`/${data.profile_type}/activities/${data.profile_id}?from=${data.from}&to=${data.to}`, "GET");
};

// Attendence
export const createAttendence = (data) => {
  return retryHandleApis(`/attendance`, "POST", data);
};

export const getAttendenceByStudentId = (student_id) => {
  return retryHandleApis(`/attendance/student/${student_id}`, "GET");
};
export const getAttendenceBySlotId = (slot_id) => {
  return retryHandleApis(`/attendance/slot/2`, "GET");
};

// report and mark
export const getResultExamByStudentId = (student_id) => {
  return retryHandleApis(`/exam_result/student/${student_id}`, "GET");
};

export const getSubjects = () => {
  return retryHandleApis("/subject_config?pageSize=100", "GET");
};

export const createSubject = (data) => {
  return retryHandleApis("/subject_config", "POST", data);
};

// Assessment
export const createAssessment = (data) => {
  return retryHandleApis("/subject_config?pageSize=100", "GET", data);
};


