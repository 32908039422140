import retryHandleApis from "../configs/handleApis";

export const getProfileUser = (type, id) => {
    return retryHandleApis(`/${type}/${id}`, "GET");
};

export const updateProfileUser = (type, id, data) => {
    return retryHandleApis(`/${type}/${id}`, "PUT", data);
};

export const getStudentContract = (id) => {
    return retryHandleApis(`/class_student/student/${id}?pageSize=20`, "GET");
};

export const updateStudentContract = (id, data) => {
    return retryHandleApis(`/class_student/${id}`, "PUT", data);
};

