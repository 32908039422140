import React, { useContext } from "react";
import { Input, Button, Tooltip, message } from "antd";
import { KeyOutlined, AlertOutlined, CopyOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { StoreContext } from "../../stores";

const PWD_CHARS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*";
const PWD_LENGTH = 12;

function PasswordGenerator(props) {
    const { form, propsKey } = props;
    const { t } = useTranslation(["common"]);

    const { password, setPassword } = useContext(StoreContext);

    const generateRandomPassword = () => {
        let randPassword = Array(PWD_LENGTH)
            .fill(PWD_CHARS)
            .map((x) => x[Math.floor(Math.random() * x.length)])
            .join("");
        setPassword(randPassword);
        form.setFieldsValue({ password: randPassword });
    };

    return (
        <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} addonBefore={<KeyOutlined />} size="large" />
        // <div className="flex__start__center">

        //     <Tooltip title="Generate random password">
        //         <Button size="large" type="text" icon={<AlertOutlined />} onClick={() => generateRandomPassword()} />
        //     </Tooltip>
        //     <Tooltip title="Copy to clipboard">
        //         <Button
        //             size="large"
        //             type="text"
        //             icon={<CopyOutlined />}
        //             onClick={() => {
        //               navigator.clipboard.writeText(password);
        //               message.success(t("copy_success"));
        //             }}
        //             />
        //     </Tooltip>
        //             </div>
    );
}

export default PasswordGenerator;
