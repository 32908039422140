import retryHandleApis from "../configs/handleApis";

export const getNotify = () => {
    return retryHandleApis("/notify", "GET");
};
export const createNotify = (data) => {
    return retryHandleApis("/notify", "POST", data);
};
export const makeAsRead = (id) => {
    return retryHandleApis(`/notify/${id}/mark-as-read`, "GET");
};
export const updateNotify = (data) => {
    return retryHandleApis(`/notify/${data.id}`, "PUT", data);
};