import { Button, Drawer } from "antd";
import React, { useContext } from "react";
import { StoreContext } from "../../stores";
import { CloseOutlined } from "@ant-design/icons";

function CommonDrawer(props) {
    const { visibleDrawer, setVisibleDrawer, drawerContent, setInitialForm, setDrawerContent } = useContext(StoreContext);

    return (
        visibleDrawer &&
        drawerContent && (
            <Drawer
                title={drawerContent.title}
                placement="right"
                closable={false}
                maskClosable={false}
                onClose={() => {
                    setVisibleDrawer(false);
                    setDrawerContent({});
                    setInitialForm(null);
                }}
                visible={visibleDrawer}
                getContainer={false}
                width="600px"
                extra={
                    <Button
                        onClick={() => {
                            setVisibleDrawer(false);
                            setDrawerContent({});
                            setInitialForm(null);
                        }}
                        // size="small"
                        type="text"
                        icon={<CloseOutlined />}
                    />
                }
            >
                {drawerContent.content}
            </Drawer>
        )
    );
}

export default CommonDrawer;
