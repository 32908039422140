import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Button,
  message,
  Modal,
  Select,
  Space,
  Typography,
} from "antd";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { updateNotify, makeAsRead } from "../../apis/notifications";
import { getTeachers } from "../../apis/teachers";

const AlertType = ["warning", "warning", "danger"];
const { confirm } = Modal;

function Notification(props) {
  const { user_id, notify } = props;
  const [isRead, setIsRead] = useState(notify.is_read);
  //   const [selectedTeacher, setSelectedTeacher] = useState();
  const showConfirm = async () => {
    const res = await getTeachers();
    let selectedTeacher;
    // setTeachers(res.data || []);
    if (res.status === 200) {
      confirm({
        title: "Vui lòng chọn giáo viên dạy thay",
        icon: <ExclamationCircleOutlined />,
        content: (
          <Fragment>
            <Select
              style={{ width: "100%" }}
              size="large"
              onChange={(e) => (selectedTeacher = e)}
            >
              {res.data ? (
                res.data.map((option, index) => (
                  <Select.Option
                    value={option.account_id}
                    key={option.key || index}
                  >
                    {option.name}
                  </Select.Option>
                ))
              ) : (
                <Select.Option value={0} key={0}>
                  Loading...
                </Select.Option>
              )}
            </Select>
          </Fragment>
        ),
        width: "430px",
        okText: "Thêm ngay",
        onOk: async () => {
          console.log("selectedTeacher::", selectedTeacher);
          await updateNotify(
            Object.assign(notify, {
              is_read: 1,
              is_confirm: true,
              accounts: [notify.creator_id, selectedTeacher],
              confirmer_id: user_id,
              substitute_id: selectedTeacher,
            })
          );
          message.success("Thành công!");
        },
      });
    }
  };

  return (
    <Alert
      onClick={() => {
        if (!isRead) {
          makeAsRead(notify.id);
          setIsRead(true)
        }
      }}
      className={`${isRead ? "readed" : "unread"}`}
      showIcon
      icon={
        <Avatar size={"large"} src={notify.creator && notify.creator.photo} />
      }
      message={`${notify.creator && notify.creator.name} - ${notify.title}`}
      description={notify.body}
      type={AlertType[notify.type - 1]}
      action={
        !notify.is_confirm ? (
          <Space className="flex__center__center flex__column">
            <Button
              onClick={() => !notify.substitute_id && showConfirm()}
              size="small"
              type="primary"
            >
              Accept
            </Button>
            <Button
              onClick={async () => {
                await updateNotify(
                  Object.assign(notify, {
                    is_read: 1,
                    is_confirm: false,
                    accounts: [notify.creator_id],
                    confirmer_id: user_id,
                  })
                );
              }}
              size="small"
              danger
              type="link"
            >
              Decline
            </Button>
          </Space>
        ) : (
          <Typography.Text type="success">Xác nhận!</Typography.Text>
        )
      }
    />
  );
}

export default Notification;
