/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { userLogin, getUserProfile } from "../apis/auth";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import Cookies from "js-cookie";

export const AuthContext = React.createContext(null);
const DEV = true;
const UserEnum = ["ADMIN", "MANAGER", "TEACHER", "PARENT", "STUDENT"];

export default ({ children }) => {
    const { t } = useTranslation(["auth"]);
    const [user, setUser] = useState();

    // render message by response status
    const handleLoginByStatus = async (result) => {
        if (!result) return;

        switch (result?.status) {
            case 200:
            case 201: {
                Cookies.set("token", result.data.token);
                window.location.pathname = "/";
                await getUser(result.data.token);
                return message.success(t("login_success_msg"));
            }
            case 400:
            case 401:
            case 404:
                message.error(t("login_error_msg"));
                break;

            default:
                message.error(t("server_error_msg"));
                break;
        }
    };

    // login system, save token to cookies
    const login = async (values) => {
        const result = await userLogin(values);
        handleLoginByStatus(result);
    };

    // Clear current user and logout
    const logout = () => {
        Cookies.remove("token");
        Cookies.remove("role");
        const isLogin = window.location.pathname === "/login";
        if (!isLogin) window.location.pathname = "/login";
    };

    const requestNewPassword = async (values) => {
        if (DEV === true) {
            return {
                status: 200,
            };
        } else
            return {
                status: 400,
            };
    };

    // return user, if null getUserProfile and update store
    const getUser = async (token) => {
        const profileUser = await getUserProfile(token || Cookies.get("token"));
        if (profileUser && profileUser.status === 200) {
            Cookies.set("role", UserEnum[profileUser.data.role] || "TEACHER");
            setUser(Object.assign(profileUser.data, { role: UserEnum[profileUser.data.role] }));
            return profileUser.data;
        } else return logout();
    };

    const authStores = {
        login: login,
        logout: logout,
        getUser: getUser,
        requestNewPassword: requestNewPassword,
        user: user,
        setUser: setUser
    };

    return <AuthContext.Provider value={authStores}>{children}</AuthContext.Provider>;
};
