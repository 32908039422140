import React, { useContext, useEffect, useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { StoreContext } from "../../stores";

const MenuByRoles = (props) => {
    const { t } = useTranslation(["common"]);
    const [selectedKey, setSelectedKey] = useState();
    const { me } = useContext(StoreContext);
    const routeByRoles = {
        PARENT: [
            {
                key: "dashboard",
                label: "dashboard",
            },
        ],
        STUDENT: [
            {
                key: "dashboard",
                label: <Link to="/dashboard">{t("dashboard")}</Link>,
            },
            {
                key: "educations",
                label: <Link to="/educations">{t("educations")}</Link>,
            },
            {
                key: "report_mark",
                label: <Link to={`/report/student/${me?.id}/mark`}>{t("report_mark")}</Link>,
            },
        ],
        TEACHER: [
            {
                key: "dashboard",
                label: <Link to="/dashboard">{t("dashboard")}</Link>,
            },
            {
                key: "educations",
                label: <Link to="/educations">{t("educations")}</Link>,
            },
            {
                key: "report_daily",
                label: <Link to="/report">{t("report_daily")}</Link>,
            },
            {
                key: "report_class",
                label: <Link to="/report/class">{t("report_class")}</Link>,
            },
            {
                key: "report_student",
                label: <Link to="/report/student">{t("report_student")}</Link>,
            },
            {
                key: "report_mark",
                label: <Link to="/report/mark">{t("report_mark")}</Link>,
            },
        ],
        ADMIN: [
            {
                key: "dashboard",
                label: <Link to="/dashboard">{t("dashboard")}</Link>,
            },
            {
                key: "employee",
                label: t("employee"),
                children: [
                    {
                        key: "staffs",
                        label: <Link to="/staffs">{t("staffs")}</Link>,
                    },
                    {
                        key: "accountants",
                        label: <Link to="/employee/accountants">{t("accountants")}</Link>,
                    },
                    {
                        key: "super-accountants",
                        label: <Link to="/employee/super-accountants">{t("super-accountants")}</Link>,
                    },
                    {
                        key: "hrs",
                        label: <Link to="/employee/hrs">{t("hrs")}</Link>,
                    },
                    {
                        key: "foreign-elements",
                        label: <Link to="/employee/foreign-elements">{t("foreign-elements")}</Link>,
                    },
                    {
                        key: "salers",
                        label: <Link to="/employee/salers">{t("salers")}</Link>,
                    },
                ],
            },
            {
                key: "teachers",
                label: <Link to="/teachers">{t("teachers")}</Link>,
            },
            {
                key: "students",
                label: <Link to="/students">{t("students")}</Link>,
            },
            {
                key: "parents",
                label: <Link to="/parents">{t("parents")}</Link>,
            },
            {
                key: "centers",
                label: <Link to="/centers">{t("centers")}</Link>,
            },
            {
                key: "courses",
                label: <Link to="/courses">{t("courses")}</Link>,
            },
            {
                key: "classes",
                label: <Link to="/classes">{t("classes")}</Link>,
            },
            {
                key: "exams",
                label: <Link to="/exams">{t("exams")}</Link>,
            },
            {
                key: "customers",
                label: <Link to="/customers">{t("customers")}</Link>,
            },
            {
                key: "libraries",
                label: <Link to="/libraries">{t("libraries")}</Link>,
            },
        ],
        SALER: [
            {
                key: "customers",
                label: <Link to="/customers">{t("customers")}</Link>,
            },
            {
                key: "teachers",
                label: <Link to="/teachers">{t("teachers")}</Link>,
            },
            {
                key: "students",
                label: <Link to="/students">{t("students")}</Link>,
            },
            {
                key: "parents",
                label: <Link to="/parents">{t("parents")}</Link>,
            },
            {
                key: "centers",
                label: <Link to="/centers">{t("centers")}</Link>,
            },
            {
                key: "courses",
                label: <Link to="/courses">{t("courses")}</Link>,
            },
        ],
        ACCOUNTANT: [
            {
                key: "staffs",
                label: <Link to="/staffs">{t("staffs")}</Link>,
            },
            {
                key: "centers",
                label: <Link to="/centers">{t("centers")}</Link>,
            },
            {
                key: "courses",
                label: <Link to="/courses">{t("courses")}</Link>,
            },
            {
                key: "customers",
                label: <Link to="/customers">{t("customers")}</Link>,
            },
        ],
        SUPER_ACCOUNTANT: [
            {
                key: "staffs",
                label: <Link to="/staffs">{t("staffs")}</Link>,
            },
            {
                key: "centers",
                label: <Link to="/centers">{t("centers")}</Link>,
            },
            {
                key: "courses",
                label: <Link to="/courses">{t("courses")}</Link>,
            },
            {
                key: "customers",
                label: <Link to="/customers">{t("customers")}</Link>,
            },
        ],
        FOREIGN_ELEMENTS: [
            {
                key: "dashboard",
                label: <Link to="/dashboard">{t("dashboard")}</Link>,
            },
            {
                key: "staffs",
                label: <Link to="/staffs">{t("staffs")}</Link>,
            },
            {
                key: "teachers",
                label: <Link to="/teachers">{t("teachers")}</Link>,
            },
            {
                key: "centers",
                label: <Link to="/centers">{t("centers")}</Link>,
            },
            {
                key: "courses",
                label: <Link to="/courses">{t("courses")}</Link>,
            },
            {
                key: "customers",
                label: <Link to="/customers">{t("customers")}</Link>,
            },
        ],
        HR: [
            {
                key: "staffs",
                label: <Link to="/staffs">{t("staffs")}</Link>,
            }
        ],
        GUEST: [],
    };

    return (
        <Menu
            className="main__menu flex__center__center flex__column"
            theme="dark"
            selectedKeys={selectedKey}
            items={routeByRoles[me?.role || "GUEST"]}
            onClick={(e) => setSelectedKey(e.keyPath)}
            triggerSubMenuAction="click"
        />
    );
};

export default MenuByRoles;
