import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Form, Input, Button, Checkbox, Row, Col, DatePicker, Space, TimePicker } from "antd";
import { useTranslation } from "react-i18next";
import { StoreContext } from "../../stores";
import PasswordGenerator from "../PasswordGenerator";
import SelectOptions from "./SelectOptions";
import moment from "moment";
import { AuthContext } from "../../stores/auth";
import UploadResource from "../UploadResource";
import CheckboxGroup from "./CheckboxGroup";
import SalesProcess from "./components/SalesProcess";
import { from } from "../../configs/options";
import Payment from "./components/Payment";
import SocialInsurance from "./components/SocialInsurance";
import ProbationaryContracts from "./components/ProbationaryContracts";
import IdentificationInfo from "./components/IdentificationInfo";
import Education from "./components/Education";
import WorkProcess from "./components/WorkProcess";
import OfficialContract from "./components/OfficialContract";
import { WarningOutlined } from "@ant-design/icons";

const { RangePicker } = TimePicker;

function CreateForm(props) {
    const { form_items, form_name } = props;
    const form_type = form_name.split("_")[0];
    const [form] = Form.useForm();
    const { t } = useTranslation(["common"]);
    const { loading, initialForm, me } = useContext(StoreContext);
    const initialKeys = (initialForm && Object.keys(initialForm)) || [];
    const [selectedDate, setSelectedDate] = useState();
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf("day");
    };
    useEffect(() => {
        return () => {
            form.resetFields();
        };
    }, [form]);

    // const renderFormItems = useCallback((item) => {
    //     const map = new Map();
    //     const initialKeys = (initialForm && Object.keys(initialForm)) || [];
    //     map.set("content", <Input.TextArea rows={3} />);
    //     map.set("from", <DatePicker onChange={(e) => form.setFieldsValue({ [item.key]: e.valueOf() })} format="DD/MM/YYYY" size="large" />);
    //     map.set("to", <DatePicker onChange={(e) => form.setFieldsValue({ [item.key]: e.valueOf() })} format="DD/MM/YYYY" size="large" />);
    //     map.set(
    //         "visa_expiration_date",
    //         <DatePicker
    //             onChange={(e) => {
    //                 setSelectedTo(e?.valueOf());
    //             }}
    //             format="DD/MM/YYYY"
    //             size="large"
    //         />
    //     );
    //     map.set("date_range", <DatePicker.RangePicker disabledDate={disabledDate} format="DD/MM/YYYY" size="large" />);
    //     map.set("dob", <DatePicker format="YYYY-MM-DD" size="large" />);
    //     map.set(
    //         "datetime",
    //         <Space>
    //             <DatePicker
    //                 onChange={(date, dateString) => {
    //                     setSelectedDate(dateString);
    //                 }}
    //                 format="YYYY-MM-DD"
    //                 size="large"
    //             />
    //             <RangePicker
    //                 onChange={(time, timeString) => {
    //                     setSelectedFrom(timeString[0]);
    //                     setSelectedTo(timeString[1]);
    //                 }}
    //                 minuteStep={5}
    //                 placeholder={["Start time", "End time"]}
    //                 format={"HH:mm"}
    //                 size="large"
    //             />
    //         </Space>
    //     );
    //     map.set(
    //         "daterange",
    //         <DatePicker.RangePicker
    //             // onChange={(time, timeString) => {}
    //             // placeholder={["Start at", "End at"]}
    //             format={"DD/MM/YYYY"}
    //             size="large"
    //         />
    //     );
    //     map.set("password", <PasswordGenerator form={form} propsKey={item.key} />);
    //     map.set("photo", <UploadResource upload_type={form_name && form_name.split("_")[1]} aspect={item.label === "photo" ? 1 / 1 : 3 / 2} />);
    //     map.set("contract_file", <UploadResource upload_type={form_name && form_name.split("_")[1]} aspect={false} />);
    //     !initialKeys.includes("center_id") && map.set("center_id", <SelectOptions initOptions={item.options} form={form} propsKey={item.key} />);
    //     !initialKeys.includes("courseId") && map.set("courseId", <SelectOptions initOptions={item.options} form={form} propsKey={item.key} />);
    //     !initialKeys.includes("classId") && map.set("classId", <SelectOptions initOptions={item.options} form={form} propsKey={item.key} />);
    //     !initialKeys.includes("teacherId") && map.set("teacherId", <SelectOptions initOptions={item.options} form={form} propsKey={item.key} />);
    //     !initialKeys.includes("parentId") && map.set("parentId", <SelectOptions initOptions={item.options} form={form} propsKey={item.key} />);
    //     !initialKeys.includes("contractType") && map.set("contractType", <SelectOptions initOptions={item.options} form={form} propsKey={item.key} />);
    //     !initialKeys.includes("nationality") && map.set("nationality", <SelectOptions initOptions={item.options} form={form} propsKey={item.key} />);
    //     !initialKeys.includes("position") && map.set("position", <SelectOptions initOptions={item.options} form={form} propsKey={item.key} />);
    //     !initialKeys.includes("type") && map.set("type", <SelectOptions initOptions={item.options} form={form} propsKey={item.key} />);
    //     !initialKeys.includes("subject_config_id") &&
    //         map.set("subject_config_id", <SelectOptions initOptions={item.options} form={form} propsKey={item.key} />);

    //     map.set("default", <Input type={item.type || "text"} disabled={form_type === "create" ? initialKeys.includes(item.key) : false} size="large" />);
    //     if (item.checkbox) return <CheckboxGroup form={form} propsKey={item.key} />;

    //     return map.get(item.key) || map.get("default");
    // }, []);

    // const valuesByUserCreate = (values, key, initialForm) => {
    //     switch (key) {
    //         case "create_student":
    //             return {
    //                 photo: imageUpload,
    //                 password: password,
    //             };
    //         case "create_teacher":
    //             return {
    //                 photo: imageUpload,
    //                 password: password,
    //             };
    //         case "create_staff":
    //             return {
    //                 photo: imageUpload,
    //             };
    //         case "update_teacher_contract":
    //             return {
    //                 contract_file: imageUpload,
    //                 contract_signing_date: moment(values.daterange[0]).valueOf(),
    //                 contract_expiration_date: moment(values.daterange[1]).valueOf(),
    //             };
    //         case "update_teacher_visa":
    //             return {
    //                 visa_expiration_date: selectedTo,
    //             };
    //         case "create_course":
    //             return {
    //                 from: moment(values.daterange[0]).valueOf(),
    //                 to: moment(values.daterange[1]).valueOf(),
    //                 created_by: user && user.id,
    //                 image: imageUpload,
    //             };
    //         case "create_center":
    //             return {
    //                 from: selectedFrom,
    //                 to: selectedTo,
    //                 created_by: user && user.id,
    //                 coverPicture: imageUpload,
    //             };
    //         case "create_slot":
    //             return {
    //                 from: moment(selectedDate + " " + selectedFrom).valueOf(),
    //                 to: moment(selectedDate + " " + selectedTo).valueOf(),
    //                 teacherId: user?.owner_id,
    //             };
    //         case "update_course":
    //             return {
    //                 from: moment(initialForm?.form).valueOf(),
    //                 to: moment(initialForm?.to).valueOf(),
    //             };

    //         default:
    //             return {};
    //     }
    // };

    const renderFormItem = (item) => {
        switch (item.type) {
            case "password":
                return <Input.Password size="large" />;

            case "datepicker":
                return <DatePicker format="DD/MM/YYYY" size="large" />;

            case "file":
                return <UploadResource upload_type={form_name && form_name.split("_")[1]} aspect={false} />;

            case "select":
                return <SelectOptions initOptions={item.options} form={form} propsKey={item.key} />;

            case "checkbox":
                return <Checkbox>{t(item.label).toUpperCase()}</Checkbox>;

            case "checkbox_groups":
                return <CheckboxGroup form={form} propsKey={item.key} />;

            case "daterange":
                return (
                    <DatePicker.RangePicker
                        onChange={(time, timeString) => {
                            form.setFieldsValue({ from: time[0]?.valueOf() });
                            form.setFieldsValue({ to: time[1]?.valueOf() });
                        }}
                        placeholder={["Start at", "End at"]}
                        format={"DD/MM/YYYY"}
                        size="large"
                    />
                );

            case "datetime":
                return (
                    <Space>
                        <DatePicker
                            disabledDate={disabledDate}
                            onChange={(date, dateString) => {
                                setSelectedDate(dateString);
                            }}
                            format="YYYY-MM-DD"
                            size="large"
                        />
                        <RangePicker
                            onChange={(time, timeString) => {
                                form.setFieldsValue({ from: moment(selectedDate + " " + timeString[0]).valueOf() });
                                form.setFieldsValue({ to: moment(selectedDate + " " + timeString[1]).valueOf() });
                            }}
                            minuteStep={5}
                            placeholder={["Start time", "End time"]}
                            format={"HH:mm"}
                            size="large"
                        />
                    </Space>
                );
            case "timerange":
                return (
                    <RangePicker
                        onChange={(time, timeString) => {
                            form.setFieldsValue({ from: moment(initialForm.currentDate + " " + timeString[0]).valueOf() });
                            form.setFieldsValue({ to: moment(initialForm.currentDate + " " + timeString[1]).valueOf() });
                        }}
                        minuteStep={5}
                        placeholder={["Start time", "End time"]}
                        format={"HH:mm"}
                        size="large"
                    />
                );

            case "sale_process":
                return <SalesProcess form={form} propsKey={item.key} />;

            case "payment":
                return <Payment form={form} propsKey={item.key} />;

            case "social_insurance":
                return <SocialInsurance form={form} propsKey={item.key} />;

            case "probationary_contracts":
                return <ProbationaryContracts form={form} propsKey={item.key} />;

            case "official_contract":
                return <OfficialContract form={form} propsKey={item.key} />;

            case "identification_info":
                return <IdentificationInfo form={form} propsKey={item.key} />;

            case "education":
                return <Education form={form} propsKey={item.key} />;

            case "work_process":
                return <WorkProcess form={form} propsKey={item.key} />;

            default:
                return <Input type={item.type || "text"} disabled={form_type === "create" ? initialKeys.includes(item.key) : false} size="large" />;
        }
    };

    return (
        <Form
            form={form}
            initialValues={initialForm}
            requiredMark={false}
            name={form_name || "create_form"}
            layout="vertical"
            onFinish={async (values) => {
                await props.onFinish(Object.assign(initialForm || {}, values));
            }}
            autoComplete="off"
            className="creator-form"
        >
            {form_items.map((item) => (
                <Form.Item
                    hidden={item.type === "hidden"}
                    name={item.key}
                    label={item.type === "checkbox" ? null : t(item.label)}
                    key={item.key}
                    rules={[{ required: item.required, message: <span><WarningOutlined /> {t("blank_error_msg")}</span> }]}
                >
                    {renderFormItem(item)}
                </Form.Item>
            ))}

            <Form.Item className="item__submit" wrapperCol={{ offset: 0, span: 24 }}>
                <Button className="ant-btn__submit" loading={loading} size="large" type="primary" htmlType="submit" style={{ width: "100%" }}>
                    {t(`${form_name.split("_")[0]}`)}
                </Button>
            </Form.Item>
        </Form>
    );
}

export default CreateForm;
