export const paymentItems = [
    {
        key: "payment_code",
        label: "payment_code",
    },
    {
        key: "payment_bank",
        label: "payment_bank",
    }
];

export const idCardItems = [
    {
        key: "id_type",
        label: "id_type",
    },
    {
        key: "id_code",
        label: "id_code",
    },
    {
        key: "id_date",
        label: "id_date",
    },
    {
        key: "id_issuer",
        label: "id_issuer",
    },
];

export const educationItems = [
    {
        key: "education_level",
        label: "education_level",
    },
    {
        key: "education_branch",
        label: "education_branch",
    },
    {
        key: "education_school",
        label: "education_school",
    },
    {
        key: "education_cert",
        label: "education_cert",
    },
];

export const probationaryContractsItems = [
    {
        key: "no",
        label: "no",
    },
    {
        key: "time",
        label: "time",
    },
];

export const socialInsuranceItems = [
    {
        key: "si_code",
        label: "si_code",
    },
    {
        key: "si_salary",
        label: "si_salary",
    },
    {
        key: "si_rate",
        label: "si_rate",
    },
];

export const workProcessItems = [
    {
        key: "time",
        label: "time",
    },
    {
        key: "position",
        label: "position",
    },
    {
        key: "salary",
        label: "salary",
    },
];

export const staffOtherField = [
    {
        key: 'job_title',
        label: 'job_title'
    },
    {
        key: 'level',
        label: 'level'
    },
    {
        key: 'department',
        label: 'department'
    },
    {
        key: "current_address",
        label: "current_address",
    },
    {
        key: "permanent_address",
        label: "permanent_address",
    },
    {
        key: "nationality",
        label: "nationality",
    },
    {
        key: "sex",
        label: "sex",
    },
    {
        key: "start_at",
        label: "start_at",
    },
    {
        key: "tax_code",
        label: "tax_code",
    },
    {
        key: "taxpayers",
        label: "taxpayers",
    }
];
export const salerOtherField = [
    {
        key: "code",
        label: "code",
    },
];
