import retryHandleApis from "../configs/handleApis";

export const getParents = () => {
  return retryHandleApis("/parent", "GET");
};

export const filterParents = (options) => {
  return retryHandleApis(
    `/parent?name=${options.name || ""}&email=${options.email || ""}&phone=${options.phone || ""}&position=${options.position || ""}&nationality=${
      options.nationality || ""
    }`,
    "GET"
  );
};

export const createParent = (data) => {
  return retryHandleApis("/parent", "POST", data);
};

export const getStudents = () => {
  return retryHandleApis("/student/?pageSize=100", "GET");
};

export const getStudentById = (id) => {
  return retryHandleApis(`/student/${id}`, "GET");
};
export const getParentById = (id) => {
  return retryHandleApis(`/parent/${id}`, "GET");
};
export const getStudentByClassId = (id) => {
  return retryHandleApis(`/class_student/class/${id}`, "GET");
};

export const filterStudents = (options) => {
  console.log(options);
  return retryHandleApis(
      `/students?name=${options.name || ""}&email=${options.email || ""}&phone=${options.phone || ""}&position=${options.position || ""}&nationality=${
          options.nationality || ""
      }&subject=${options.subjects || ""}`,
      "GET"
  );
};

export const createStudent = (data) => {
  return retryHandleApis("/student", "POST", data);
};
