import React, { useContext, useState } from "react";
import { message, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { StoreContext } from "../../stores";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { handleUpdateProfileUser } from "../../apis/usingApis";

let access_token = Cookies.get("token");

const UploadResource = (props) => {
    // let { profile_type, profile_id } = useParams();
    // console.log("profile_type:: ", profile_type);
    const uploadType = new Map();
    uploadType.set("teacher", "http://112.78.3.91:3333/api/upload/photo/user");
    uploadType.set("center", "http://112.78.3.91:3333/api/upload/photo/center");
    uploadType.set("course", "http://112.78.3.91:3333/api/upload/photo/course");
    uploadType.set("default", "http://112.78.3.91:3333/api/upload/photo");
    const { upload_type, type, resource, resourceData, aspect } = props;
    const [file, setFile] = useState();
    const { setImageUpload, setVisibleModal, setModalContent } = useContext(StoreContext);

    const onFinish = (info) => {
        let data = {};
        data[resourceData.key] = info.file.response.photo;
        handleUpdateProfileUser({ profile_type: resourceData.profile_type, profile_id: resourceData.profile_id, data: data }, () => {});
        props.setResource(Object.assign(resource, data));
        setVisibleModal(false);
        setModalContent(null);
    };

    const uploadProps = {
        listType: type === "link" ? "text" : "picture-card",
        name: "photo",
        action: uploadType.get(upload_type) || uploadType.get("default"),
        headers: {
            authorization: `Bearer ` + access_token,
        },
        onChange(info) {
            if (info.file.status === "done") {
                setFile(info.file.response.photo);
                setImageUpload(info.file.response.photo);
                resourceData && onFinish(info);
            }
        },
        async onPreview(file) {
            let src = file.url;
            if (!src) {
                src = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file.originFileObj);
                    reader.onload = () => resolve(reader.result);
                });
            }
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow.document.write(image.outerHTML);
        },
        onRemove() {
            setFile(null);
            setImageUpload(null);
        },
    };

    return type === "link" ? (
        <Upload maxCount={1} {...uploadProps}>
            {!file && "+ Upload"}
        </Upload>
    ) : aspect ? (
        <ImgCrop aspect={aspect || 1 / 1} rotate modalWidth={700}>
            <Upload maxCount={1} {...uploadProps}>
                {!file && "+ Upload"}
            </Upload>
        </ImgCrop>
    ) : (
        <Upload maxCount={1} {...uploadProps}>
            {!file && "+ Upload"}
        </Upload>
    );
};

export default UploadResource;
