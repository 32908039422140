import { Button, Input, Typography } from "antd";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { StoreContext } from "../../../stores";

// let tempProcess = [
//     "Học bổng? Đầu ra, danh sách trường con có thể học ở Sing và 1 số quốc gia khác. A-Level có phù hợp không. Học AL có thi được Ielts không, các trường đại học ở Sing và Canada con có thể theo học khi kết thúc chương trình học AL",
//     "Con đổi lịch test vào 24/12/21.Thời gian test dự kiến: Test trường:  khoảng 15h30 - 16h45 Test hệ AL: 17h00 GV test hệ: Thầy Jovan",
//     "26/12: mẹ ghi danh trong ngày hội thảo online để được tặng khoá tiền tiểu học. Lịch test dự kiến vẫn vào 9h00 thứ 7 ngày 22/01/2022",
// ];

const removeLastItemArray = (array) => {
    console.log("array::", array);
    array.pop();
    return array.slice();
};

const ProcessInput = (props) => {
    const { process, index, salesProcess } = props;
    const [value, setValue] = useState(process);
    return (
        <Fragment>
            <Typography.Text>
                Lần {index}
                {index === salesProcess.length && (
                    <span
                        onClick={() => {
                            props.actions(removeLastItemArray(salesProcess));
                        }}
                        className="item--right"
                        type="link"
                    >
                        Xóa
                    </span>
                )}
            </Typography.Text>
            <Input.TextArea
                placeholder={`SALE Lần ${index}`}
                rows={3}
                style={{ marginBottom: 12 }}
                size="large"
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                    let newProcess = salesProcess;
                    newProcess[index - 1] = e.target.value;
                    props.actions(newProcess.slice());
                }}
            />
        </Fragment>
    );
};

function SalesProcess(props) {
    const { initialForm } = useContext(StoreContext);
    const [salesProcess, setSalesProcess] = useState(initialForm ? JSON.parse(initialForm?.sales_process) : [""]);
    const { form } = props;
    const handleAddProcess = () => {
        if (salesProcess.slice(-1)[0] === "") return;
        let newProcess = salesProcess;
        newProcess.push("");
        setSalesProcess(newProcess.slice());
    };

    useEffect(() => {
        form.setFieldsValue({ sales_process: JSON.stringify(salesProcess) });
    }, [salesProcess]);

    return (
        <div className="child_groups">
            {salesProcess?.length ? (
                salesProcess?.map((process, index) => (
                    <ProcessInput salesProcess={salesProcess} actions={setSalesProcess} key={index} process={process} index={index + 1} />
                ))
            ) : (
                <ProcessInput salesProcess={salesProcess} actions={setSalesProcess} process={null} index={1} />
            )}
            <Button type="dashed" style={{ width: "100%", marginBottom: 10 }} onClick={handleAddProcess}>
                THÊM
            </Button>
        </div>
    );
}

export default SalesProcess;
