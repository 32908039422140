import retryHandleApis from "../configs/handleApis";

export const userLogin = (data) => {
  return retryHandleApis("/login", "POST", data);
};

export const userLogout = () => {
  return retryHandleApis("/logout", "POST");
};

export const getUserProfile = (token) => {
  return retryHandleApis("/auth/me", "GET", { token: token });
};