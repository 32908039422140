import { Modal } from "antd";
import React, { useContext } from "react";
import { StoreContext } from "../../stores";

function CommonModal(props) {
    const { setModalContent, modalContent, visibleModal, setVisibleModal } = useContext(StoreContext);

    return visibleModal && modalContent ? (
        <Modal
            width={modalContent.width}
            title={modalContent.title}
            visible={visibleModal}
            onOk={() => {
                try {
                    modalContent?.onOk();
                    setVisibleModal(false);
                } catch (error) {}
            }}
            onCancel={() => {
                try {
                    setVisibleModal(false);
                    modalContent.onCancel();
                } catch (error) {}
                setModalContent({});
            }}
            okText={modalContent.okText}
            cancelText={modalContent.cancelText}
            centered={modalContent.centered}
            footer={modalContent.footer}
        >
            {modalContent.content}
        </Modal>
    ) : null;
}

export default CommonModal;
