/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { routes } from "../configs/router";
import { Layout, Typography } from "antd";
import Loading from "../components/Loading";
import { StoreContext } from "../stores";
import HeaderComponent from "../components/Header";
// import io from "socket.io-client";
import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { checkValidSesson, returnLogin } from "../utils";
import apiRequest from "../configs/apiRequest";
import { UserEnum } from "../configs/enum";
import CommonDrawer from "../components/Common/CommonDrawer";
import CommonModal from "../components/Common/CommonModal";
import CommonSider from "../components/Common/CommonSider";
import { version } from "../../package.json";

// const socket = io("ws://112.78.3.91:3333");

const { Footer, Content } = Layout;

function App() {
    const { setLoading, loading, setMe } = useContext(StoreContext);
    // const [isConnected, setIsConnected] = useState(socket.connected);

    const { data: currentUser, isLoading } = useQuery(
        ["user"],
        () => {
            setLoading(true);
            if (window.location.pathname === "/login") {
                setLoading(false);
                return "UNAUTHEN";
            }
            if (!Cookies.get("token")) returnLogin();
            return apiRequest.get(`${process.env.REACT_APP_API_BASE_URL}/auth/me`).then((me) => {
                checkValidSesson(me);
                setMe(Object.assign(me.data, { role: UserEnum[me.data.role] }));
                setLoading(false);
                return me.data;
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );

    // console.log("user::", user);

    // useEffect(() => {
    //     if (user) {
    //         socket.on("connect", () => {
    //             setIsConnected(true);
    //         });

    //         socket.on("disconnect", () => {
    //             setIsConnected(false);
    //         });

    //         socket.on("notify:create", (data) => {
    //             console.log("notify:create::", data);
    //             if (user && data.accounts.includes(user.id)) {
    //                 setUpdateNotify(true);
    //                 notification.open({
    //                     message: null,
    //                     description: <Notification notify={data} />,
    //                     className: "ox_notification",
    //                     closeIcon: <div></div>,
    //                     top: 64,
    //                 });
    //             }
    //         });

    //         socket.on("notify:update", (data) => {
    //             console.log("update::", data);
    //             if (user && !data.accounts.includes(user.id)) {
    //                 setUpdateNotify(true);
    //                 console.log("updated!!!", user);
    //                 notification.open({
    //                     message: null,
    //                     description: <Notification notify={data} />,
    //                     className: "ox_notification",
    //                     closeIcon: <div></div>,
    //                     top: 64,
    //                 });
    //             }
    //         });
    //     }

    //     return () => {
    //         socket.off("connect");
    //         socket.off("disconnect");
    //         socket.off("pong");
    //     };
    // }, [user]);

    if (isLoading) return <Loading />;
    return (
        currentUser && (
            <Layout>
                <Router>
                    <CommonSider currentUser={currentUser} />
                    <Layout>
                        {currentUser !== "UNAUTHEN" && <HeaderComponent currentUser={currentUser} />}
                        <Content>
                            <Suspense fallback={<Loading />}>
                                <Switch>
                                    {routes.map((route, index) => (
                                        <Route key={index} path={route.path} exact={route.exact} component={route.component}></Route>
                                    ))}
                                </Switch>
                            </Suspense>
                            {/* display loading from global state */}
                            {loading && <Loading />}
                        </Content>
                        <Footer className="flex__center__center">
                            Copyright © 2021 by <a href="/">&nbsp;Oxbridge </a>. All Rights Reserved.{" "}
                            <div className="flex__center__end flex__column app-version ">
                                <Typography.Text>Version: {version}</Typography.Text>
                            </div>
                        </Footer>
                    </Layout>
                </Router>

                <CommonDrawer />
                <CommonModal />
            </Layout>
        )
    );
}

export default App;
